.cell {
  width: 50%;
}
.cell .wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 10rem;
  margin: 1rem 1rem 0;
  padding-top: 2.2rem;
  background: rgba(255, 255, 255, 0.2);
}

.spinner {
  width: 4rem;
  height: 4rem;
}

.spinner.spinner3 {
  border-top: 3px solid #005ca8;
  border-right: 3px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotation 0.8s linear infinite;
  animation: rotation 0.8s linear infinite;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
